import React from 'react';

import { PhoneInputControlProps } from './phone-input-control-type';
import { InputControl } from '../input-control';

export const PhoneInputControl: React.FC<PhoneInputControlProps> = ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    hint,
    allowInternational,
    ...rest
}: PhoneInputControlProps) => {
    // const patternFr = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/g;
    // -- patternInternational résoud une regex standard de numéro international ou parrernFr (qui lui gère les séparateurs [\s.-])
    const patternInternational = /^(((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1})|((?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4})$/g;

    const hintFr = '';
    const hintInternational = '';

    return (
        <InputControl
            type="tel"
            hint={allowInternational ? hintInternational : hintFr}
            pattern={patternInternational}
            {...rest}
        />
    );
};
