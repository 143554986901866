/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react';
import { Field, FieldProps, FormikProps } from 'formik';
import { Input } from 'modules/shared-ui-advanced/vendors/semantic-ui';
import { useTranslation } from 'react-i18next';

import { InputControlProps } from './input-control-type';

import styles from './input-control-component.module.css';

export const InputControl: React.FC<InputControlProps> = ({
    name,
    required,
    pattern,
    type,
    hint,
    equalValue,
    equalValueMessage,
    notEqualValue,
    notEqualValueMessage,
    arrayContext,
    onChange,
    onBlur,
    className,
    noPaste,
    maxLength,
    upperCase = false,
    ...rest
}: InputControlProps) => {
    const { t } = useTranslation();
    const validate = useCallback(
        (value: string): string | undefined => {
            let result;
            if (!value && !!required) {
                result = t('shared.form.required');
            } else if (!!value && !!pattern) {
                // TODO : comportement a revoir
                if (pattern.test(value) === true) {
                    result = undefined;
                } else if (!pattern.test(value)) {
                    result = t('shared.form.pattern');
                }
            }

            // On interdit les emojis, peut importe le type de l'input
            if (value !== undefined && value !== null && value !== '') {
                const emojiRegex = /\p{Extended_Pictographic}/gu;
                if (emojiRegex.test(value)) {
                    result = t('shared.form.emoji');
                }
            }

            if (!result) {
                if (!!value && !!equalValue && value !== equalValue) {
                    result = equalValueMessage || t('shared.form.equal');
                } else if (!!value && !!notEqualValue && value === notEqualValue) {
                    result = notEqualValueMessage || t('shared.form.notEqual');
                }
            }

            return result;
        },
        [required, pattern, equalValue, equalValueMessage, notEqualValue, notEqualValueMessage, t],
    );

    const getErrorMessage = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (form: FormikProps<any>) => {
            if (!form || !form.errors || !form.touched) {
                return undefined;
            }

            let formError;
            let formTouched;
            if (arrayContext) {
                const nestedErrors = form.errors[arrayContext.parentFieldName]
                    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      (form.errors[arrayContext.parentFieldName] as any)[arrayContext.index]
                    : null;

                if (nestedErrors) {
                    formError = nestedErrors[arrayContext.fieldName];
                }
                const nestedTouched = form.touched[arrayContext.parentFieldName]
                    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      (form.touched[arrayContext.parentFieldName] as any)[arrayContext.index]
                    : null;

                if (nestedTouched) {
                    formTouched = nestedTouched[arrayContext.fieldName];
                }
            } else {
                formError = form.errors[name];
                formTouched = form.touched[name];
            }

            if (formTouched && formError) {
                return formError;
            }

            return undefined;
        },
        [name, arrayContext],
    );

    return (
        <Field
            required
            name={name}
            validate={validate}
            render={({ field, form }: FieldProps) => {
                const errorMessage = getErrorMessage(form);
                return (
                    <div className={className}>
                        {hint && <div className={styles.hint}>{hint}</div>}
                        <Input
                            className={`${styles.input} ${upperCase ? styles.upperCase : ''}`}
                            type={type || 'text'}
                            {...rest}
                            name={field.name}
                            value={field.value}
                            onPaste={(e: React.ClipboardEvent) => {
                                if (noPaste) e.preventDefault();
                            }}
                            onChange={(event, data) => {
                                field.onChange(event);
                                if (onChange) {
                                    onChange(event, data);
                                }
                            }}
                            onBlur={(e: any) => {
                                field.onBlur(e);
                                if (onBlur) {
                                    onBlur();
                                }
                            }}
                            maxLength={maxLength}
                        />
                        {errorMessage && <div className={styles.error}>{errorMessage}</div>}
                    </div>
                );
            }}
        />
    );
};
