import React from 'react';

import { InputControl } from '../input-control';
import { EmailInputControlProps } from './email-input-control-type';

export const EmailInputControl: React.FC<EmailInputControlProps> = (props: EmailInputControlProps) => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;

    return <InputControl type="email" pattern={pattern} {...props} noPaste />;
};
