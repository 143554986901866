import React, { useRef, useCallback, useState } from 'react';

import { Popup, Responsive } from 'vendors/semantic-ui';

import { config, configUI } from 'config';

import { FileInputControlProps } from './file-input-control-type';

import styles from './file-input-control-component.module.css';

export const FileInputControl: React.FC<FileInputControlProps> = (props: FileInputControlProps) => {
    const {
        accept,
        acceptHint,
        replaceHint = false,
        onChange,
        placeholder,
        name,
        sizeLimit = config.cdn.fileMaxSize,
    } = props;
    const ref = useRef<HTMLInputElement>(null);

    const [error, setError] = useState(false);

    const onBtnClick = useCallback(() => {
        if (ref && ref.current) {
            ref.current.click();
        }
    }, [ref]);

    const hint = `Le fichier ne doit pas faire plus de ${sizeLimit}Ko.`;
    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event && event.target && event.target.files && event.target.files.length > 0) {
                event.persist();
                const { target } = event;
                const { files } = target;
                if (files && files.length > 0) {
                    if (sizeLimit && files.length > 0 && sizeLimit * 1024 < files[0].size) {
                        setError(true);
                    } else if (onChange) {
                        onChange(event);
                        setError(false);
                    }
                }
            }
        },
        [onChange, sizeLimit],
    );
    return (
        <React.Fragment>
            <Responsive as={React.Fragment} maxWidth={configUI.responsive.tablet.maxWidth}>
                <input
                    ref={ref}
                    hidden
                    type="file"
                    name={name}
                    onChange={handleChange}
                    accept={accept}
                    placeholder={placeholder}
                />

                <button className={styles.uploadBtn} type="button" onClick={onBtnClick} />
                <span>Charger une image</span>
            </Responsive>
            <Responsive as={React.Fragment} minWidth={configUI.responsive.computer.minWidth}>
                <div className={styles.desktopContainer}>
                    <Popup
                        header="Charger un fichier"
                        position="top center"
                        trigger={<button className={styles.uploadBtn} type="button" onClick={onBtnClick} />}
                    />
                    <input
                        ref={ref}
                        hidden
                        type="file"
                        name={name}
                        onChange={handleChange}
                        accept={accept}
                        placeholder={placeholder}
                    />
                    {replaceHint && acceptHint && <span className={styles.altHint}>{acceptHint}</span>}
                </div>
            </Responsive>
            {!replaceHint && hint && sizeLimit && <div className={styles.hint}>{hint}</div>}
            {!replaceHint && acceptHint && <div className={styles.hint}>{acceptHint}</div>}
            {error && <div className={styles.error}>Le fichier n&apos;est pas valide</div>}
        </React.Fragment>
    );
};
