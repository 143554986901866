import React, { useCallback } from 'react';
import { Radio } from 'modules/shared-ui-advanced/vendors/semantic-ui';
import { Field, FieldProps, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';

import { RadioInputControlProps } from './radio-input-control-type';

import styles from './radio-input-control-component.module.css';

export const RadioInputControl: React.FC<RadioInputControlProps> = ({
    options,
    required,
    name,
    horizontal,
    spaced,
    centered,
    disabled,
    arrayContext,
    onChange,
}: RadioInputControlProps) => {
    const { t } = useTranslation();
    const validate = useCallback(
        (value: string): string | undefined => {
            let result;
            if (!value && required) {
                result = t('shared.form.required');
            }

            return result;
        },
        [required, t],
    );

    const getErrorMessage = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (form: FormikProps<any>) => {
            if (!form || !form.errors || !form.touched) {
                return undefined;
            }

            let formError;
            let formTouched;
            if (arrayContext) {
                const nestedErrors = form.errors[arrayContext.parentFieldName]
                    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      (form.errors[arrayContext.parentFieldName] as any)[arrayContext.index]
                    : null;

                if (nestedErrors) {
                    formError = nestedErrors[arrayContext.fieldName];
                }
                const nestedTouched = form.touched[arrayContext.parentFieldName]
                    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      (form.touched[arrayContext.parentFieldName] as any)[arrayContext.index]
                    : null;

                if (nestedTouched) {
                    formTouched = nestedTouched[arrayContext.fieldName];
                }
            } else {
                formError = form.errors[name];
                formTouched = form.touched[name];
            }

            if (formTouched && formError) {
                return formError;
            }

            return undefined;
        },
        [name, arrayContext],
    );

    return (
        <Field
            name={name}
            validate={validate}
            render={({ field, form }: FieldProps) => {
                const errorMessage = getErrorMessage(form);
                return (
                    <div
                        className={`${horizontal ? styles.horizontal : styles.vertical} ${
                            spaced ? styles.spaced : ''
                        } ${centered ? styles.centered : ''}`}
                    >
                        {options.map((option, key) => {
                            const selected = field.value === option.value;
                            let label: string | JSX.Element = option.text as string;
                            if (option.richLabel) {
                                label = (
                                    <div className={styles.richLabel}>
                                        <div className={styles.radioInputBtn}>
                                            {selected && <div className={styles.selected} />}
                                        </div>
                                        {option.richLabel}
                                    </div>
                                );
                            }
                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <div className={styles.item} key={key}>
                                    <Radio
                                        name={name}
                                        label={label}
                                        value={option.value}
                                        disabled={disabled}
                                        onChange={() => {
                                            form.setFieldValue(field.name, option.value);
                                            if (onChange) {
                                                onChange(option.value);
                                            }
                                        }}
                                        onBlur={() => form.setFieldTouched(field.name)}
                                        checked={field.value === option.value}
                                        className={`${option}`}
                                    />
                                </div>
                            );
                        })}
                        {errorMessage && <div className={styles.error}>{errorMessage}</div>}
                    </div>
                );
            }}
        />
    );
};
