import React from 'react';
import { Field, FieldProps } from 'formik';
import { Checkbox } from 'vendors/semantic-ui';

import { CheckBoxInputControlProps } from './checkbox-input-control-type';
import styles from './checkbox-input-control-component.module.css';

export const CheckBoxInputControl: React.FC<CheckBoxInputControlProps> = ({
    name,
    label,
    ...rest
}: CheckBoxInputControlProps) => {
    return (
        <Field
            name={name}
            render={({ field, form }: FieldProps) => {
                return (
                    <Checkbox
                        className={styles.cb}
                        name={name}
                        label={label}
                        {...rest}
                        {...field}
                        value="" // -- La valeur s'attend à revevoir un string et elle recoit un boolean, l'état est géré avec la props checked
                        checked={field.value}
                        onChange={(event, data) => form.setFieldValue(name, data.checked)}
                    />
                );
            }}
        />
    );
};
