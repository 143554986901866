import React, { useCallback } from 'react';

import { Select } from 'vendors/semantic-ui';
import { Field, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { SelectControlOption } from 'modules/shared/types';
import { SelectControlProps } from './select-control-type';

import styles from './select-control-component.module.css';

export const SelectControl: React.FC<SelectControlProps> = ({
    options,
    required,
    name,
    initialValue,
    customNoSelectionOption,
    hint,
    onChange,
    style,
    ...rest
}: SelectControlProps) => {
    const noSelectionOption = customNoSelectionOption || {
        key: '',
        value: 0,
        text: '--',
    };
    const optionsList: SelectControlOption[] = [noSelectionOption, ...options];

    const { t } = useTranslation();
    const validate = useCallback(
        (value: string): string | undefined => {
            let result;
            if (!value && required) {
                result = t('shared.form.required');
            }

            return result;
        },
        [required, t],
    );

    return (
        <Field
            name={name}
            validate={validate}
            render={({ field, form }: FieldProps) => (
                <div>
                    <div className={styles.hint}>{hint}</div>
                    <Select
                        className={`${styles.selector} custom-selector`}
                        defaultValue={initialValue}
                        options={optionsList}
                        {...field}
                        {...rest}
                        onBlur={() => form.setFieldTouched(field.name)}
                        onChange={(option, data) => {
                            form.setFieldValue(field.name, data.value);
                            if (onChange) {
                                onChange(data.value);
                            }
                        }}
                        style={style}
                    />
                    {form && form.errors && form.errors[name] && form.touched && form.touched[name] && (
                        <div className={styles.error}>{form.errors[name]}</div>
                    )}
                </div>
            )}
        />
    );
};
