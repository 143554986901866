import React, { useCallback } from 'react';
import { TextArea } from 'modules/shared-ui-advanced/vendors/semantic-ui';

import { Field, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';

import { TextAreaControlProps } from './text-area-control-type';

import styles from './text-area-control-component.module.css';

export const TextAreaControl: React.FC<TextAreaControlProps> = ({
    name,
    required,
    pattern,
    hint,
    equalValue,
    notEqualValue,
    lightgray = false,
    ...rest
}: TextAreaControlProps) => {
    const { t } = useTranslation();
    const validate = useCallback(
        (value: string): string | undefined => {
            let result;
            if (!value && required) {
                result = t('shared.form.required');
            } else if (!!value && !!pattern) {
                // TODO : comportement a revoir
                if (pattern.test(value) === true) {
                    result = undefined;
                } else if (!pattern.test(value)) {
                    result = t('shared.form.pattern');
                }
            }

            // On interdit les emojis, peut importe le type de l'input
            if (value !== undefined && value !== null && value !== '') {
                const emojiRegex = /\p{Extended_Pictographic}/gu;
                if (emojiRegex.test(value)) {
                    result = t('shared.form.emoji');
                }
            }

            if (!result) {
                if (value && equalValue && value !== equalValue) {
                    result = t('shared.form.equal');
                } else if (value && notEqualValue && value !== notEqualValue) {
                    result = t('shared.form.notEqual');
                }
            }

            return result;
        },
        [required, pattern, equalValue, notEqualValue, t],
    );

    return (
        <Field
            required
            name={name}
            validate={validate}
            render={({ field, form }: FieldProps) => {
                return (
                    <div className={lightgray ? styles.lightgray : ''}>
                        <div className={styles.hint}>{hint}</div>
                        <TextArea className={styles.input} {...field} {...rest} />
                        {form && form.errors && form.errors[name] && form.touched && form.touched[name] && (
                            <div className={styles.error}>{form.errors[name]}</div>
                        )}
                    </div>
                );
            }}
        />
    );
};
