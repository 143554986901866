/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
import React, { useCallback } from 'react';
import { Field, FieldProps } from 'formik';
import ReactDatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import { useTranslation } from 'react-i18next';
import { calculateAge } from 'modules/shared/utils';

import { DatePickerControlProps } from './date-picker-control-type';

// eslint-disable-next-line import/imports-first
import 'react-datepicker/dist/react-datepicker.css';
import regularStyles from './date-picker-control-component.module.css';
import altStyles from './date-picker-control-alt.module.css';

registerLocale('fr', fr);
setDefaultLocale('fr');

export const DatePickerControl: React.FC<DatePickerControlProps> = ({
    required,
    name,
    minAgeRequired,
    onDateChange,
    onMonthChange,
    filterDate,
    greyOutDate,
    disableKeyPress = false,
    altStyle = false,
    hideIcon,
    className,
    placeholder,
    ...rest
}: DatePickerControlProps) => {
    const { t, i18n } = useTranslation();
    const validate = useCallback(
        (value: string): string | undefined => {
            let result;
            if (!value && required) {
                result = t('shared.form.required');
            }
            if (value && minAgeRequired && calculateAge(value) < minAgeRequired) {
                result = t('shared.form.minAgeRequired');
            }

            return result;
        },
        [required, t, minAgeRequired],
    );

    const locale = ['fr-FR', 'fr', 'FR'].indexOf(i18n.language) > -1 ? 'fr' : 'en';
    const dateFormat = locale === 'fr' ? 'P' : 'MM/dd/yyyy';

    const styles = !altStyle ? regularStyles : altStyles;

    return (
        <Field
            required
            name={name}
            validate={validate}
            render={({ field, form }: FieldProps) => {
                return (
                    <React.Fragment>
                        <div className={styles.rootContainer}>
                            <ReactDatePicker
                                {...field}
                                {...rest}
                                inline={altStyle}
                                locale={locale}
                                autoComplete="off"
                                placeholderText={placeholder || '-- / -- / ----'}
                                selected={field.value as Date}
                                calendarClassName={altStyle ? styles.customCalendar : undefined}
                                weekDayClassName={altStyle ? () => styles.weekDay : undefined}
                                className={`${className} ${styles.datePicker}`}
                                dayClassName={date =>
                                    `${greyOutDate && greyOutDate(date) ? `${styles.greyedOut}` : ''} ${styles.day}`
                                }
                                onChange={value => {
                                    if (value !== null) {
                                        if (onDateChange) {
                                            onDateChange(value);
                                        }
                                        form.setFieldValue(name, value);
                                    }
                                }}
                                onMonthChange={onMonthChange}
                                filterDate={filterDate}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                dateFormat={dateFormat}
                                onKeyDown={disableKeyPress ? e => e.preventDefault() : undefined}
                                renderCustomHeader={
                                    altStyle
                                        ? ({
                                              date,
                                              decreaseMonth,
                                              increaseMonth,
                                              prevMonthButtonDisabled,
                                              nextMonthButtonDisabled,
                                          }) => (
                                              <div className={styles.customCalendarHeader}>
                                                  <div className={styles.customMonths}>
                                                      <span
                                                          onClick={decreaseMonth}
                                                          className={`react-datepicker__navigation ${styles.decreaseMonth}`}
                                                          style={{
                                                              visibility: prevMonthButtonDisabled
                                                                  ? 'hidden'
                                                                  : 'visible',
                                                          }}
                                                      >
                                                          &#8249;
                                                      </span>
                                                      <span className={styles.monthLabel}>
                                                          {t(`shared.calendar.months.${date.getMonth()}`)}{' '}
                                                          {date.getFullYear()}
                                                      </span>
                                                      <span
                                                          onClick={increaseMonth}
                                                          className={`react-datepicker__navigation ${styles.increaseMonth}`}
                                                          style={{
                                                              visibility: nextMonthButtonDisabled
                                                                  ? 'hidden'
                                                                  : 'visible',
                                                          }}
                                                      >
                                                          &#8250;
                                                      </span>
                                                  </div>
                                              </div>
                                          )
                                        : undefined
                                }
                            />
                            {!(altStyle || hideIcon) && (
                                <div className={styles.iconButton}>
                                    <div className={styles.icon} />
                                </div>
                            )}
                        </div>
                        {form && form.errors && form.errors[name] && form.touched && form.touched[name] && (
                            <div className={styles.error}>{form.errors[name]}</div>
                        )}
                    </React.Fragment>
                );
            }}
        />
    );
};
